import React from 'react'
import styled from 'styled-components'
import Text from 'components.v2/Text'

export const Title = styled(props => (
  <Text variant={{ screen: 'tiny', default: 'tertiary' }} bold {...props} />
))`
  margin-bottom: 10px;
  line-height: 1.9em;
`

export const ContentSection = styled.div``

export const Content = styled(props => (
  <Text
    variant={{ screen: 'tertiary', default: 'secondary' }}
    light
    {...props}
  />
))`
  line-height: 1.9em;
`

export const AddressDiv = styled.section`
  width: 220px;
`