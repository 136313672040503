import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import get from 'lodash/get'

import VenueStatus from './VenueStatusOrange'

class VenueStatusContainer extends React.Component {
  static propTypes = {
    info: PT.object,
    isLoading: PT.bool,
    position: PT.string,
  }

  render() {
    const { info, isLoading, position } = this.props
    const { status, openingHours, busyUntil, vendorProperty } = info
    const timezone = get(vendorProperty,'timezone')
    const show = status === 'CLOSED' || status === 'OFFLINE'
    if (!show || isLoading) return null

    return (
      <VenueStatus
        {...info.address || {}}
        status={status}
        openingHours={openingHours}
        isOpen
        layoutPosition={position}
        busyUntil={busyUntil}
        timezone={timezone}
      />
    )
  }
}

export default connect(
  createSelector(
    state => state.vendor.info,
    ({ info, isLoading }) => ({ info, isLoading }),
  ),
  null,
)(VenueStatusContainer)
