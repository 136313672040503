import React from 'react'
import PropTypes from 'prop-types'
import { Title, ContentSection, Content, AddressDiv } from './styled'

const VenueAddress = ({ address, ...props }) => {
  const { address1, address2, suburb, state, postcode } = address || {}

  return (
    <AddressDiv {...props}>
      <Title>ADDRESS</Title>
      <ContentSection>
        <Content>
          {address1} {address2}
        </Content>
        <Content>{[`${suburb} ${postcode}`, state].join(', ')}</Content>
      </ContentSection>
    </AddressDiv>
  )
}

VenueAddress.propTypes = {
  address: PropTypes.object,
}

export default VenueAddress
