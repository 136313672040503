import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import styled from 'styled-components'

import { H4, Text } from 'components/Text'
import {
  orderToCustomisationText,
  orderToTotalUnitPrice,
} from 'pages/order/modules/order'

import { formatPrice } from 'utils/currency'
import NumberPicker from './NumberPicker'
import values from 'lodash/values'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { getPrice } from 'utils/currency'
import Decimal from 'decimal.js'

const OrderList = ({ orders, isStatic, openModal, venueId }) => (
  <Fragment>
    {orders.map((order, index) => {
      const customisationText = orderToCustomisationText(order)
      const {
        quantity,
        name,
        changeOrderQty,
        upsizeOrder,
        serialisedOptions,
      } = order

      const handleOpenModal = () => {
        openModal({ ...order, index, venueId })
      }

      const optionGroups = flatten(values(order.optionGroups))

      const sizeOption = optionGroups.find(
        f => /size/.test(f.name.toLowerCase()) && f.type === 'RADIO',
      )

      const parsedOption = JSON.parse(serialisedOptions)

      const currentSize = flatten(parsedOption).find(f =>
        get(sizeOption, 'options', []).find(o => o.id === f),
      )

      const currentOptionSize = get(sizeOption, 'options', []).find(
        o => o.id === currentSize,
      )

      const getNextItem = (currentOption, options) => {
        const remainingItems = filter(
          options,
          i =>
            getPrice(i.price) > getPrice(currentOption.price) &&
            i.id !== currentOption.id,
        )

        const allPrices = map(remainingItems, i => getPrice(i.price))

        const itemIndex = allPrices.indexOf(Math.min(...allPrices))

        return remainingItems[itemIndex]
      }

      const nextItem = getNextItem(
        currentOptionSize,
        get(sizeOption, 'options', []),
      )

      const nextItemPrice = nextItem
        ? new Decimal(getPrice(get(nextItem, 'price', 0)))
            .minus(getPrice(currentOptionSize.price))
            .toNumber()
        : 0

      const handleUpsizeOrder = index => {
        upsizeOrder(
          parsedOption.map(o =>
            o.includes(currentOptionSize.id)
              ? map(o, i => (i === currentOptionSize.id ? nextItem.id : i))
              : o,
          ),
          index,
        )
      }

      return (
        <OrderItem key={shortid.generate()}>
          <div className="flex flex-row items-center">
            {isStatic ? (
              <StaticQuantity>{quantity}</StaticQuantity>
            ) : (
              <NumberPicker
                onChange={changeOrderQty}
                number={quantity}
                min={0}
              />
            )}
            <OrderContent onClick={openModal && !isStatic && handleOpenModal}>
              <ItemName>{name}</ItemName>
              {customisationText && (
                <CustomisationText>{customisationText}</CustomisationText>
              )}
            </OrderContent>
            <ItemPrice>{formatPrice(orderToTotalUnitPrice(order))}</ItemPrice>
          </div>
          {nextItem && !isStatic && (
            <Upsize className="flex flex-row items-center">
              Upsize to {nextItem.name}?
              <UpsizePrice className="ml-auto">
                +{formatPrice(nextItemPrice)}
              </UpsizePrice>
              <UpsizeButton onClick={() => handleUpsizeOrder(index)}>
                Upsize
              </UpsizeButton>
            </Upsize>
          )}
        </OrderItem>
      )
    })}
  </Fragment>
)

OrderList.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      changeOrderQty: PropTypes.func,
    }),
  ).isRequired,
  isStatic: PropTypes.bool,
}

const UpsizeButton = styled.button`
  height: 28px;
  width: 68px;
  border-radius: 8px;
  background: var(--Green-0, #2e9a9a);
  color: var(--White, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  &:focus {
    outline: none;
  }
`

const UpsizePrice = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

const Upsize = styled.div`
  stroke-width: 1px;
  background: #eaf5f5;
  border: 1px dashed #2e9a9a;
  border-radius: 8px;
  height: 44px;
  padding: 12px 16px;
  gap: 12px;

  color: #2e9a9a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

const OrderItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`

const OrderContent = styled.div`
  flex: 1;
  margin-left: 15px;
  margin-right: 10px;
`

const CustomisationText = styled.span`
  color: #999999;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`

const StaticQuantity = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemName = styled(H4)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

const ItemPrice = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

export default OrderList
