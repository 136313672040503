import React from 'react'
import PT from 'prop-types'
import Transition from 'react-transition-group/Transition'

import { VenueStatusWrapper, VenueStatusContent } from './styled'
import { STATUS_CLOSED, STATUS_OFFLINE, STATUS_BUSY } from 'pages/vendor/const'
import humaniseStatus from 'components.v2/VenueImage/humaniseStatus'
import moment from 'moment'

const duration = 100

const defaultStyle = {
  height: 0,
}

const transitionStyles = {
  entering: { height: 0 },
  entered: { height: 52 },
}

const Content = ({ openingHours, status, busyUntil, timezone }) => {

  const busyDate = moment(busyUntil).tz(timezone,true)

  const now = moment().tz(timezone, true)

  const isBusyMode = busyDate.isValid() && busyDate.isAfter(now)

  const remainingMinutes = Math.floor(moment.duration(busyDate.diff(now)).asMinutes())

  const messages = new Map()
    .set(
      STATUS_CLOSED,
      `Sorry, we ${humaniseStatus(status, openingHours, busyUntil, timezone).replace(
        'Opens',
        'open',
      )}!`,
    )
    .set(STATUS_OFFLINE, 'Sorry, we’re temporarily unavailable.')
    .set(STATUS_BUSY, remainingMinutes > 0 ? `Sorry, we're unavailable for ${remainingMinutes} minutes!` : 'Sorry, we’re temporarily unavailable.')

  const busyModeStatus = isBusyMode ? STATUS_BUSY : false

  return <VenueStatusContent>{messages.get(busyModeStatus||status)}</VenueStatusContent>
}

export default class extends React.Component {
  state = {
    isOpen: false,
  }

  static propTypes = {
    isOpen: PT.bool,
    layoutPosition: PT.string,
    openingHours: PT.array,
  }

  static defaultProps = {
    isOpen: false,
    layoutPosition: '',
    openingHours: [],
  }

  componentDidMount() {
    this.setState({
      isOpen: true,
    })
  }

  render() {
    return (
      <Transition in={this.state.isOpen} timeout={duration}>
        {state => (
          <VenueStatusWrapper
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            layoutPosition={this.props.layoutPosition}
          >
            <Content {...this.props} />
          </VenueStatusWrapper>
        )}
      </Transition>
    )
  }
}
