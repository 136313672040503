import moment from 'moment-timezone'

/**
 * Constants
 */

// Note that index 0 must be Sunday for following computations
const DATE_LIST = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const DATA_TIME_FORMAT = 'HH:mm:ss'

/**
 * Exports
 */

export default (status, openingHours, busyUntil, tz) => {
  if (!status) {
    return
  }

  
  const busyDate = moment(busyUntil).tz(tz,true)

  const now = moment().tz(tz,true)

  if (busyUntil && busyDate.isValid()) { 
    const remainingMinutes = Math.floor(moment.duration(busyDate.diff(moment().tz(tz, true))).asMinutes())
    
    if (busyDate.isAfter(now) && remainingMinutes > 0) return `Sorry we're unavailable for ${remainingMinutes} minutes!`
      
    return `Sorry we’re temporarily unavailable`

  }
  
  if (status.toUpperCase() === 'OFFLINE') {
    return 'Back online soon'
  }

  if (status.toUpperCase() === 'CLOSED') {
    const { opening, daysBefore } = getNextOpeningDetails(openingHours, tz)
    const humanisedTime = moment(opening.start, DATA_TIME_FORMAT)
      .format('h:mmA')
      .replace(':00', '')
    const humanisedDay =
      (daysBefore === 0 && 'today') ||
      (daysBefore === 1 && 'tomorrow') ||
      opening.day

    return `Opens ${humanisedTime}, ${humanisedDay}!`
  }
}

/**
 * Internal Functions
 */

export const getNextOpeningDetails = (list, tz) => {
  const currDate = new Date()
  const currDay = currDate.getDay() // int; Sunday = 0, Monday = 1, and so on...
  const nextOpeningDetails = list.reduce(
    (acc, opening) => {
      // TODO: return if time is past

      let daysBefore = DATE_LIST.findIndex(x => x === opening.day) - currDay

      const openingDt = moment(opening.start, DATA_TIME_FORMAT)
      const closingDt = moment(opening.end, DATA_TIME_FORMAT)
      const openingDtAu = moment(currDate)
      const closingDtAu = moment(currDate)

      if (tz) {
        openingDtAu.tz(tz)
        closingDtAu.tz(tz)
      }
      openingDtAu.set({
        hour: openingDt.get('hour'),
        minute: openingDt.get('minute'),
        second: openingDt.get('second'),
      })
      closingDtAu.set({
        hour: closingDt.get('hour'),
        minute: closingDt.get('minute'),
        second: closingDt.get('second'),
      })

      if (daysBefore < 0) {
        daysBefore = 7 + daysBefore
      }

      if (
        daysBefore === 0 &&
        (tz
          ? moment(currDate)
              .tz(tz)
              .isAfter(closingDtAu)
          : moment(currDate).isAfter(moment(opening.end, DATA_TIME_FORMAT)))
      ) {
        daysBefore = 7
      }

      return daysBefore < acc.daysBefore ? { opening, daysBefore } : acc
    },
    { daysBefore: Infinity },
  )

  return nextOpeningDetails
}
