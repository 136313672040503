import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import Transition from 'react-transition-group/Transition'

import {
  VenueStatusWrapper,
  VenueStatusContent,
  VenueStatusDescription,
  VenueStatusLink,
  VenueStatusTitle,
  VenueStatusIcon,
} from './styled'

import VenueStatusPortal from './VenueStatusPortal'

import Icon from 'components.v2/Icon/IconV2'
import moment from 'moment'
import { STATUS_BUSY, STATUS_CLOSED } from 'pages/vendor/const'

const sanitizeUrl = str => encodeURI(str.toLowerCase())

const duration = 100

const defaultStyle = {
  height: 0,
}

const transitionStyles = {
  entering: { height: 0 },
  entered: { height: 70 },
}

const Header = ({status, busyUntil, timezone})=> {
  
  switch(status) {
    case STATUS_CLOSED:
      return <>Venue Closed{' '}</>
    case STATUS_BUSY:
      const busyDate = moment(busyUntil).tz(timezone,true)

      const now = moment().tz(timezone,true)

      if (busyDate.isValid() && busyDate.isBefore(now) || !busyDate.isValid()) return <>Sorry we’re temporarily unavailable</>
      
      const remainingMinutes = Math.floor(moment.duration(busyDate.diff(now)).asMinutes())
      return busyDate.isValid() && remainingMinutes > 0 ? <>Sorry, we're unavailable for {remainingMinutes} minutes!{' '}</> : <>Sorry we’re temporarily unavailable</>
    default:
      return <>Venue Offline{' '}</>
  }
}

const Content = ({ city, suburb, status = 'CLOSED', subcontent, timezone, busyUntil }) => {
  return (
    <VenueStatusContent>
      <VenueStatusIcon>
        <Icon src="Shopclosed.svg" />
      </VenueStatusIcon>
      <VenueStatusDescription>
        <VenueStatusTitle>
          <Header status={status} busyUntil={busyUntil} timezone={timezone}/>
          <span>{subcontent || `Browse other venues that are open`}</span>
        </VenueStatusTitle>
      </VenueStatusDescription>
      <VenueStatusLink>
        <Link to={sanitizeUrl(`/${city}`)}>See others</Link>
      </VenueStatusLink>
    </VenueStatusContent>
  )
}

// This makes sure that everything is already mounted
// before showing the VenueStatus notification so it wont
// look janky or eratic.
const DELAY = 2500

export default class extends React.Component {
  state = {
    isOpen: false,
  }

  static propTypes = {
    suburb: PT.string,
    city: PT.string,
    isOpen: PT.bool,
    layoutPosition: PT.string,
  }

  static defaultProps = {
    suburb: '',
    city: '',
    isOpen: false,
    layoutPosition: '',
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        isOpen: true,
      })
    }, DELAY)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <Transition in={this.state.isOpen} timeout={duration}>
        {state => (
          <VenueStatusWrapper
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            layoutPosition={this.props.layoutPosition}
          >
            <Content {...this.props} />
            <VenueStatusPortal />
          </VenueStatusWrapper>
        )}
      </Transition>
    )
  }
}
